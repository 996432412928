import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Flex,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";

import * as codegenapi from "../../graphql/API";
import { IGQLClient } from "../../client/gqlts";
import { MdDelete } from "react-icons/md";

interface IProps {
  gqlClient: IGQLClient;
  setErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  siteid: string | null;
  subjects: codegenapi.Subject[] | undefined;
  setEditingSubject: React.Dispatch<
    React.SetStateAction<codegenapi.Subject | undefined>
  >;
  user: any;
}

const SubjectsList = (props: IProps): JSX.Element => {
  const navigate = useNavigate();

  const { subjectid } = useParams();

  function deleteSubject(subject: codegenapi.Subject): void {
    props.gqlClient
      .gqlRemoveSubject(subject.studyid, subject.subjectid)
      .catch((response) => {
        props.setErrors((prevState) =>
          prevState ? prevState.concat(response.errors) : response.errors
        );
      });
  }

  // Create a sorted copy of the subjects array
  const sortedSubjects = useMemo(() => {
    if (!props.subjects) return [];
    return [...props.subjects].sort((a, b) => {
      const idA = a.identifier || a.subjectid;
      const idB = b.identifier || b.subjectid;
      return idA.localeCompare(idB, undefined, { sensitivity: 'base' });
    });
  }, [props.subjects]);

  return (
    <Flex direction="column">
      <h1>Subjects</h1>
      {!props.subjects && <Loader variation="linear" />}
      {props.subjects && (
        <Flex>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell as="th">&nbsp;</TableCell>
                <TableCell as="th">Status</TableCell>
                {props.user &&
                  props.user.groups.includes("subject:admin") && (
                    <TableCell as="th">Actions</TableCell>
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedSubjects.length > 0 ? (
                sortedSubjects.map((subject) => (
                  <TableRow
                    key={subject.subjectid}
                    style={{
                      backgroundColor:
                        subject.subjectid === subjectid ? "lightcyan" : "white",
                    }}
                  >
                    <TableCell>
                      <Button
                        onClick={() => navigate(`./${subject.subjectid}`)}
                      >
                        {subject.identifier
                          ? subject.identifier
                          : subject.subjectid}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <b
                        style={{
                          color:
                            subject.status === codegenapi.SubjectStatusType.OPEN
                              ? "green"
                              : "darkred",
                        }}
                      >
                        {subject.status}
                      </b>
                    </TableCell>
                    {props.user &&
                      props.user.groups.includes("subject:admin") && (
                        <TableCell>
                          <Button
                            className="table-action-button"
                            disabled={
                              subject.status ===
                              codegenapi.SubjectStatusType.DELETED
                            }
                            onClick={() => deleteSubject(subject)}
                            title="Mark as deleted"
                          >
                            <MdDelete />
                          </Button>
                        </TableCell>
                      )}
                  </TableRow>
                ))
              ) : (
                <TableRow key="empty">
                  <TableCell colSpan={5}>
                    <i>No subjects yet!</i>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Flex>
      )}
    </Flex>
  );
};

export default SubjectsList;